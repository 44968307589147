.App {
  text-align: center;
  background-image: url("/public/images/confetti.jpg");
  background-attachment: fixed;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-link {
  color: #61dafb;
}

.container {
  padding: 20px;
  border-radius: 4px;
  display: inline-block;
  max-width: 80%;
}
