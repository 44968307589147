.heading_section {
  padding: 10px;
  margin: 0 0 20px 0;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  background: #fff;
  box-shadow: 2px 2px #303030;
  padding-bottom: 20px;
  width: 90%;
}
.heading__data {
  margin: 15px;
}

.heading__header {
    color: #000;
    font-size: 22px;
  }

  .heading__info {
    margin: 0;
    padding: 0;
      color: #000;

  }
  .heading__image-container {
    max-width: 400px;

  padding: 15px 15px 5px 15px;
  margin: 0 auto;
  }
  .heading__image-container img {
  width: 100%;
  }
  .heading__data > h4 {
    font-weight: bold;
  }