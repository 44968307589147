.what3words__section {
    max-width: 200px;
    border: 2px solid red;
    border-radius: 5px;
}
.what3words__section a {
    text-decoration: none;
}
.what3words__image {
    background: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100px;
}
.what3words__image-image {
    width: 100%;
}
.what3words__label {
    background: red;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
}

.what3words__main {
    border: 2px solid red;
    border-radius: 5px;
    max-width: 300px;
    display: inline-flex;
    flex-direction: column;
    background: white;
}
a {
    text-decoration: none;
}
.what3words__set {
    display: inline-flex;
    margin: 10px;
    /* display: block;
    height: 40px; */
}
.what3words__set a {
    text-decoration: none;
    color: white;
}
.what3words__logo {
    border-radius: 5px;
    flex-basis: 20px;
    text-align: right;
    background: red;
    font-weight: bold;
    font-size: large;
    padding: 4px;
    margin-right: 5px;
}
.what3words__words {
    padding: 4px;
    background-color: white;
    font-size: large;
    color: black;
    font-weight: bold;
    width: 100%;
}
.what3words__instructions {
    color: black;
    padding: 5px;
}