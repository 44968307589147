.map__section {
  padding: 10px;
  margin: 0 0 20px 0;
  border-radius: 4px;
  display: inline-block;
  text-align: left;
  background: #191919;
  box-shadow: 2px 2px #303030;
  padding-bottom: 20px;
  width: 90%;
}
.map__data {
  margin-left: 15px;
}

.map__header {
    color: #EFBD76;
    font-size: 22px;
  }

  .map__info {
    margin: 0;
    padding: 0;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    
  }
  .map__addressBlock {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
  }
  .map__address-formatted {
    margin: 20px 0;
    display: block;
  }
  .map__address-formatted p {
    padding: 0;
    margin: 0;
  }
  .map__addressBlock > a {
    display: flex;
    align-items: stretch;
    max-height: 100px;
  }
  .map__icon {
  width: 100px;
  padding: 15px;
  }
  .map__icon-image img {
  width: 100%;

  }